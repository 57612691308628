<template>
  <div class="login">
    <h2 style="padding-top: 3rem">好用点点</h2>
    <button class="btn green" type="primary" @click="handLogin()">
      微信授权登录
    </button>
    <br />
    <button class="btn gray" @click="flaseLogin">本地模拟登陆</button>

    <!-- <div style="width:100%;padding:20px;height:200px;background:#ccc">
          <img src="/static/img/icons/icon-wallet.png" />钱包
          <img src="/img/icons/favicon-32x32.png"/>钱包
          <img :src="`${publickPath}img/icons/favicon-32x32.png`"/>钱包
    </div> -->
  </div>
</template>
<script>
var wx = require("weixin-js-sdk");
import {
  userLogin,
  falseTestGetUserInfo,
  wxBuildAuthUrl,
} from "../../utils/aRequest";

export default {
  data() {
    return {
      active: 0,
      publickPath: process.env.BASE_URL,
    };
  },
  created() {
    console.log(this.$route);
    console.log("login" + code + ":::::::::::::" + this.publickPath);
    console.log(window.location);
    console.log("--------------------");

    let code = this.getUrlCode(window.location.href, "code");
    if (code) {
      this.getToken(code);
    } else {
      this.$router.push("/login");
    }
    // console.log(this.$route)
    // this.getUrl();
  },
  watch: {},
  methods: {
    flaseLogin() {
      let openId = "o6m2ouOWAx-c-hTKXulFMly-b19c";
      falseTestGetUserInfo({ gzhOpenId: openId }).then((data) => {
        console.log("get Token");
        console.log(data);
        if (data.code == "SUCCESS") {
          console.log("SUCCESS" + data.data.sessionId);
          this.$cookie.set("sessionId", data.data.sessionId, 1); // 设置cookie
          localStorage.setItem("sessionId", data.data.sessionId);
          this.$store.dispatch("setRoleData", data.data); //vuex 存roleData 信息
          localStorage.setItem("roleData", JSON.stringify(data.data)); //本地缓存roleData 信息
          this.$router.push("/main");
        }
      });
    },
    jiequ() {
      let url = window.location.href;
      let indexNum = url.indexOf("?");
      console.log(indexNum);
      console.log(url.substring(0, indexNum));
    },
    getUrlCode(title, name) {
      return (
        decodeURIComponent(
          (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
            title
          ) || [, ""])[1].replace(/\+/g, "%20")
        ) || null
      );
    },
    getToken(code) {
      localStorage.setItem("localCode", code); // 设置code
      userLogin({ code: code }).then((data) => {
        console.log("get Token");
        console.log(data);
        if (data.code == "SUCCESS") {
          console.log("SUCCESS" + data.data.sessionId);
          this.$cookie.set("sessionId", data.data.sessionId, 1); // 设置cookie
          localStorage.setItem("sessionId", data.data.sessionId);
          this.$store.dispatch("setRoleData", data.data); //vuex 存roleData 信息
          localStorage.setItem("roleData", JSON.stringify(data.data)); //本地缓存roleData 信息

          // // 进入系统去掉拼接的code 数据
          // let url = window.location.href;
          // let indexNum = url.indexOf("?");
          // if (indexNum) {
          //   window.location.href = url.substring(0, indexNum) + "#/test";
          // }
          this.$router.push("/main");
        }
      });

      // fetch("https://axz.youlove.com.cn/mobile-backend/user/login?code="+code)
      //   .then(res => {
      //     //return 返回给上一层 ,不然下一层用不了
      //     console.log("返回给上一层 ,不然下一层用不了1")
      //     return res.json();
      //   })
      //   .then(data => {
      //     console.log(data);
      //     if(data.code == "SUCCESS"){
      //       console.log("SUCCESS"+data.data.sessionId)
      //       localStorage.setItem("sessionId", data.data.sessionId)
      //       this.$router.push("/main");
      //     }
      //   });

      // this.$http.get('https://axz.youlove.com.cn/mobile-backend/user/login', {params:{
      //   code:code
      // }})
      //   .then(function (response) {
      //     console.log("get Token");
      //     console.log(response);
      //   })
      //   .catch(function (error) {
      //     console.log(error);
      //   });
    },
    handLogin() {
      console.log("login");

      // let APPID = "wx141d83883951306e";
      let APPID = "wx64f5f6f58462089a";
      
      // let redirect_uri = encodeURIComponent('https://weixin.youlove.com.cn/')
      let redirect_uri = encodeURIComponent(window.location.href);
      let scope = "snsapi_userinfo"; //snsapi_base（不弹出授权页面，直接跳转，只能获取用户openid），snsapi_userinfo 弹出授权页面
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${APPID}&redirect_uri=${redirect_uri}&response_type=code&scope=${scope}&state=123#wechat_redirect`;
      return;

      // let da = {
      //   redirectURI: encodeURIComponent(window.location.href),
      //   scope: "snsapi_userinfo"
      // }
      // wxBuildAuthUrl(da).then((data) => {
      //   console.log(data)
      //   if (data.code == "SUCCESS") {
      //     window.location.href = data.data
      //   }
      // });
    },
  },
};
</script>

<style lang="less" scope>
.login {
  text-align: center;
}
.btn {
  width: 10rem;
  height: 3rem;
  line-height: 3rem;
  border-radius: 10px;
  border: none;
  color: #fff;
  font-size: 1rem;
  margin-top: 2rem;
}
.green {
  background-color: green;
}
.gray {
  background-color: rgb(105, 230, 198);
}
</style>
